.react-tel-input {
  font-size: 1.25rem !important;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  /* height: 3.5rem !important; */
  position: relative;
  letter-spacing: 0.01rem;
  border-radius: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  width: 100% !important;
  font-size: 1.25rem !important;
}

.react-tel-input .form-control:focus-within {
  border: thin solid red;
}

/* 
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
} */
