.categoriesmenuWrapper {
  padding: 20px;
}
.categoriesmenuWrapper .categoriesTitle h5 {
  color: #4f4f4f;
  font-weight: 900;
  font-size: 20px;
}
.allCategoriesSection ul {
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: wrap;
  gap: 4px;
}
.allCategoriesSection ul li {
  width: calc(50% - 4px);
  position: relative;
}
.allCategoriesSection ul li img {
  width: 100%;
  /* opacity:  0.5; */
  filter: brightness(40%);
}
.allCategoriesSection ul li h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-weight: 800;
  font-size: 18px;
  /* font-size: 12px;
  font-weight: 600; */
  /* white-space: nowrap; */
  white-space: wrap;
}

@media screen and (max-width: 480px) {
  .allCategoriesSection ul li h6 {
    font-size: 14px;
    font-weight: 600;
    /* color: blue; */
  }
}
.bottomfixbtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
.bottomfixbtn button {
  width: 100%;
  background: #000000;
  height: 40.91px;
  font-weight: 800;
  font-size: 13px;
  border-radius: 0px 0px 12px 12px;
  color: #fefafa;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border: 0;
}

.allCategoriesSection {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
}

.backgroundImage__menu_item {
  height: 135px;
  width: 100%;
  object-fit: cover;
  position: relative;
}
.backgroundImage__menu_item h6 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  /* font-size: 12px;
  font-weight: 600; */
  /* white-space: nowrap; */
  white-space: wrap;
}

@media screen and (min-width: 480px) {
  .backgroundImage__menu_item {
    /* height: 575px !important; */
    height: 475px !important;
  }
  .backgroundImage__menu_item h6 {
    font-weight: 800;
    font-size: 18px;
  }
}
