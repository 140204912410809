.AddAddress {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  border: 1px solid #7e8389;
  border-radius: 30px 30px 0 0;
  z-index: 10;
}

.AddAddress .closeicon {
  width: 29px;
  height: 25px;
  background: #000000;
  position: absolute;
  top: -26px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  text-align: center;
}

.AddAddress .closeicon svg {
  color: #fff;
}

.AddAddress .AddAddressform {
  padding: 20px 20px 8px 20px;
}

.AddAddress .AddAddressform .input {
  padding: 0 0 6px 0;
}

.AddAddress .AddAddressform .input input {
  width: 100%;
  font-weight: 800;
  font-size: 12px;
  color: #7e8389;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 2px 4px;
}

.AddAddress .AddAddressform .inputtwo {
  display: flex;
  gap: 8px;
}

.AddAddress .AddAddressform .inputtwo .input {
  width: calc(50% - 4px);
}

.AddAddress .AddAddressform .selectTag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.AddAddress .AddAddressform .selectTag p {
  margin-bottom: 0;
  font-weight: 800;
  font-size: 12px;
  color: #7e8389;
}

.AddAddress .AddAddressform .selectTag p sup {
  color: #e92f48;
}

.AddAddress .AddAddressform .selectTag button {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 65px;
  height: 20px;
  color: #000000;
  font-weight: 800;
  font-size: 10px;
}

.AddAddress .AddAddressform .selectTag button:focus {
  background-color: #e92f48;
  color: #fff;
}

.AddAddress .AddAddressBtn button {
  width: 100%;
  background-color: #000000;
  color: #fefafa;
  border: 0;
  border-radius: 0px 0px 12px 12px;
  height: 40.91px;
  font-weight: 800;
  font-size: 13px;
}
/* ================================ */
.address-form {
  padding: 20px;
}

.address-form .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-form .heading h5 {
  font-weight: 900;
  font-size: 20px;
}

.address-form .heading h5 sup {
  color: #e92f48;
  top: -0.1em;
}

.address-form .heading button {
  color: #e92f48;
  font-weight: 900;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.address-form .AddAddressBtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.address-form .AddAddressBtn button {
  width: 100%;
  background: #000000;
  height: 40.91px;
  font-weight: 800;
  font-size: 13px;
  border-radius: 0px 0px 12px 12px;
  color: #fefafa;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border: 0;
}

.address-form .selectTag {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.address-form .selectTag p {
  margin-bottom: 0;
  font-weight: 800;
  font-size: 20px;
  color: #7e8389;
}

.address-form .selectTag p sup {
  color: #e92f48;
}

.address-form .selectTag button {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  width: 100px;
  height: 40px;
  color: #000000;
  font-weight: 800;
  font-size: 15px;
}

.address-form .selectTag button:focus {
  background-color: #e92f48;
  color: #fff;
}
@media screen and (max-width: 480px) {
  .address-form .selectTag p {
    margin-bottom: 0;
    font-weight: 800;
    font-size: 14px;
  }

  .address-form .selectTag button {
    background: #ffffff;
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    width: 70px;
    height: 30px;
    color: #000000;
    font-weight: 800;
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .address-form form {
    width: 90vw !important;
  }
}
