.ProcessToPayWrapper {
  padding: 20px;
}

.ProcessToPayWrapper .totalOrder {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProcessToPayWrapper .totalOrder p {
  font-weight: 900;
  font-size: 17px;
  color: #424242;
}

.ProcessToPayWrapper .addressWrap .addressHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ProcessToPayWrapper .addressWrap .addressHeading h5 {
  font-weight: 900;
  font-size: 20px;
}

.ProcessToPayWrapper .addressWrap .addressHeading h5 sup {
  color: #e92f48;
  top: -0.1em;
}

.ProcessToPayWrapper .addressWrap .addressHeading button {
  color: #e92f48;
  font-weight: 900;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.ProcessToPayWrapper .addressWrap {
  padding: 36px 0 0 0;
}

.ProcessToPayWrapper .addressWrap textarea {
  width: 100%;
  color: red !important;
  color: #212529 !important;
  font-weight: bold;
  font-size: 12px;
  background: rgba(229, 229, 229, 0.51);
  border-radius: 10px;
  border: 0;
  padding: 4px;
  margin-bottom: 50px;
}

.ProcessToPayWrapper .addressWrap textarea::placeholder {
  color: #212529 !important;
}

.ProcessToPayWrapper .addressWrap textarea.outline {
  border: 1px solid #e92f48;
}

.process__pay__bottomfixbtn {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.process__pay__bottomfixbtn button {
  width: 100%;
  background: #000000;
  height: 40.91px;
  font-weight: 800;
  font-size: 13px;
  border-radius: 0px 0px 12px 12px;
  color: #fefafa;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  border: 0;
}

.ProcessToPay__addresses {
  color: #212529 !important;
  height: 100px;
  font-weight: bold;
  font-size: 12px;
  background: rgba(229, 229, 229, 0.51);
  border-radius: 10px;
  border: 0;
  padding: 4px;
  margin-bottom: 10px;
  position: relative;
}

.ProcessToPay__addresses img {
  position: absolute;
  right: 10px;
  top: 40%;
}

.ProcessToPay__addresses.selected {
  box-shadow: 0 0 20px #4dabf7;
}

.ProcessToPay__addresses.notSelected {
  box-shadow: 0 0 20px indianred;
}

.ProcessToPay__addresses__pickup label {
  font-family: Mulish;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 18px;
  letter-spacing: -0.08px;
}
.ProcessToPay__addresses__pickup label span {
  color: #e92f48;
}
