input,
select,
textarea {
  color: #212529 !important;
}

input::placeholder,
select,
textarea::placeholder {
  color: #212529 !important;
}

textarea:focus,
input:focus {
  color: #212529 !important;
}
